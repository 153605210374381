/* based on https://raw.githubusercontent.com/jwarby/pygments-css/master/zenburn.css */

.highlight {
  margin: 0;
  padding: 1em;
  background-color: #2B2B2B;
  border-radius: 4px;
  
  code, pre {
    color:#fdce93;
  }
  
  .hll {
  }
  
  .err {
    color:#e37170;
    background-color:#3d3535;
  }
  
  .k {
    color:#A9B7C6;
  }
  
  .p {
    color:#A9B7C6;
  }
  
  .cs {
    color:#cd0000;
    font-weight:700;
  }
  
  .gd {
    color:#cd0000;
  }
  
  .ge {
    color:#ccc;
    font-style:italic;
  }
  
  .gr {
    color:red;
  }
  
  .go {
    color:gray;
  }
  
  .gs {
    color:#ccc;
    font-weight:700;
  }
  
  .gu {
    color:purple;
    font-weight:700;
  }
  
  .gt {
  color:#0040D0;
  }

  .kc {
  color:#dca3a3;
  }

  .kd {
  color:#cc7832;
  }

  .kn {
  color:#dfaf8f;
  font-weight:700;
  }

  .kp {
  color:#cdcf99;
  }

  .kr {
  color:#cdcd00;
  }

  .ni {
  color:#c28182;
  }

  .ne {
  color:#c3bf9f;
  font-weight:700;
  }

  .nn {
  color:#8fbede;
  }

  .vi {
  color:#ffffc7;
  }

  .nc {
    color: #98A8BA;
  }

  .c {
  color:#7f9f7f;
  }
  
  .c1 {
    color: #808080;
    font-style: italic;
  }
  
  .cm {
    color: #629755;
    font-style: italic;
  }

  .l {
  color:#ccc;
  }

  .n {
  color:#dcdccc;
  }

  .o {
  color:#f0efd0;
  }
  
  .k {
    color: #cc7832;
  }

  .gh {
  color:#dcdccc;
  font-weight:700;
  }

  .gi {
  color:#00cd00;
  }

  .ld {
  color:#cc9393;
  }

  .m {
  color:#8cd0d3;
  }

  .na {
  color:#9ac39f;
  }

  .nb {
  color:#efef8f;
  }
  
  .nf {
    color: #93A3B3;
  }
  
  .s {
    color: #6a8759;
  }
  
}
