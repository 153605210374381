

$text-color-base: #F7F7F7 !default;
$text-color-header: #9FA2A4 !default;
$sans-serif: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol" !default;
$serif: Georgia, Times, 'Times New Roman', serif !default;
$monospace: Menlo, Monaco, Consolas, 'Courier New', monospace !default;
$font-family: $sans-serif !default;

body {
  color: $text-color-base;
  font-family: $font-family;
  font-size: 1em;
  line-height: 1.5em;
}

h1, h2, h3, h4, h5, h6 {
  color: $text-color-header;
}

main h1 {
  color: #BF6326;
  font-size: 1.8em;
}

main code {
  font-family: $monospace;
}
