@charset 'utf-8';

@import 'sanitize.css/sanitize';

@import 'variables';
@import 'typography';

@import 'idea_dark';

html {
  min-height: 100%;
}

body {
  background-color: $color_dark;
}

header,
nav ul,
main,
footer {
  width: 64em;
  max-width: calc(100% - 4em);
  margin: 0 auto;
}

nav {
  position: fixed;
  background-color: #2D3031;
  width: 100%;
  border-bottom: 1px solid #262626;

  ul {
    list-style: none;
    display: block;
    padding: 0;

    li {
      display: inline;
      margin: 0;
      padding: 0;
      vertical-align: middle;
      
      a {
        color: #9FA2A4;
        text-decoration: none;
        display: inline-block;
        padding: 0.25em 2em;
      }
      
      a.active {
        background-color: #1D1E1F;
        border-bottom: 3px solid #3B73BB;
        margin-bottom: -1px;
      }
      
    }

  }
}

header {
  text-align: center;
  color: white;
  padding: 4em 1.5em 0 1.5em;
  margin-bottom: -2em;
  
  img {
    border-radius: 50%;
    border: 2px solid #2D3031;
    width: 140px;
    height: 140px;
    margin: 1em;
  }

  h2 {
    font-size: 1.4em;
  }
  
  ul {
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    
    li {
      display: inline;
      
      a {
        text-decoration: none;
        display: inline-block;
        padding: 1em 0.75em 0 0.75em;
        
        svg {
          display: inline-block;
          width: 24px;
          height: 24px;
          fill: $color_light !important;
        }

      } 
    }
    
  }
  
}

main {
  padding: 4em 2em 2em 2em;
  
  a {
    color: #488BF8;
  }
}

footer {
  padding-bottom: 2em;
  
  ul {
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    
    li {
      display: inline;
      
      a {
        text-decoration: none;
        display: inline-block;
        margin-right: 0.75em;
        
        svg {
          display: inline-block;
          width: 24px;
          height: 24px;
          fill: $color_light !important;
        }
        
      } 
    }
    
  }
    
  p {
    text-align: center;
    
    a {
      display: inline-block;
      padding: 0 0.5em;
      
      svg {
        width: 0.75em;
        height: 0.75em;
        fill: $color_light !important;
      }
    }
  }
  
}

ul.posts {
  list-style: none;
  padding: 0;
  
  li {
    
    a.post_link {
      display: block;
      text-decoration: none;
      font-size: 1.2em;
      font-weight: bold;
      padding: 0.33em 0;
    }
    
    time {
      color: $text-color-header;
      font-style: italic;
    }
    
  }
  
}

@media only screen and (max-width: 420px) {
  
  header,
  nav ul,
  main,
  footer {
    max-width: 100%;
  }
  
  nav {
    ul {
      li {
        a {
          padding: 0.5em 0.5em;
        }
      }
    }
  }
  
  header {
    padding-top: 4em;
  }
  
  main {
    padding: 4em 1em 2em 1em;
  }
  
}
